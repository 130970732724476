import "./styles.scss";

import React from "react";
import { Link } from "gatsby";

import { useLocale } from "@context/locale";

import ArrowIcon from "@icons/arrow.svg";

function Breadcrumps({ data }) {
  const { t } = useLocale();

  return (
    <div className="breadcrumbs">
      <div className="container">
        <Link to={t("index_link")} className="breadcrumbs__item">
          {t("Strona główna")}
        </Link>
        {data.length > 0 && <ArrowIcon width={13} height={10} />}
        {Array.isArray(data) ? (
          data.map((item, index) =>
            index !== data.length - 1 ? (
              <>
                <Link
                  to={item.href}
                  className="breadcrumbs__item"
                  key={item.href}
                >
                  {item.name}
                </Link>
                <ArrowIcon width={13} height={10} />
              </>
            ) : (
              <span className="breadcrumbs__item">{item.name}</span>
            )
          )
        ) : (
          <span>{data}</span>
        )}
      </div>
    </div>
  );
}

export default Breadcrumps;
